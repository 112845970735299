
import React, { useEffect } from 'react'
import { BannerWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, LazyImage, } from '../../Styled/Elements'
// import {  } from '../../Styled/AllImages'
import { ContactButton } from '../../Styled/CustomElements'
import { AppGallery, AppStore, BannerImg, BannerImgEn, Landmarks, Mosques, Pattern, PlayStore, Stores } from '../../Styled/AllImages'
import { Link } from 'react-router-dom'

const Banner = () => {
    
    const { t, i18n } = useTranslation();
    


    return (
        <BannerWrapper className='bannerWrapper' id='home'>
            <img src={Pattern} className='themePattern bannerPattern1' />
            <Container>
                <img src={Pattern} className='themePattern bannerPattern2' />
                <Row>
                    <Col md={12} lg={5} xl={6} className='bannerImgWrapper'>
                        <Box className='bannerImg text-center'>
                        {i18n.language === 'ar' ? (
                            <LazyImage src={BannerImg} />
                        ) : (
                            <LazyImage src={BannerImgEn} />
                        )}
                        </Box>
                    </Col>
                    <Col md={12} lg={7} xl={6} className='bannerContentWrap'>
                        <Box className='contentLayers'>
                            <Box className='bannerHeadings'>
                                <HeadingStyled className='bannerMainHeading main-heading' color='var(--black)' size="50px">{t('DiscoverHistory')}</HeadingStyled>
                                <HeadingStyled className='banner-sub-heading'>{t('YourGuide')}</HeadingStyled>
                            </Box>
                            <Box className='appPlatforms'>
                                <Link to="https://apps.apple.com/us/app/athar-almadinah-almonawarah/id6642691357" target='_blank'>
                                    <AppStore />
                                </Link>
                                <Link to="https://play.google.com/store/apps/details?id=com.atharalmadinah.app" target='_blank'>
                                    <PlayStore />
                                </Link>
                                <Link to="https://appgallery.huawei.com/app/C112361829" target='_blank'>
                                    <AppGallery />
                                </Link>
                            </Box>
                            <Box className='bannerCategories'>
                                <Box className='bannerCategoryThumb'>
                                    <img src={Mosques} />
                                    <span>{t('Mosques')}</span>
                                </Box>
                                <Box className='bannerCategoryThumb'>
                                    <img src={Landmarks} />
                                    <span>{t('Landmarks')}</span>
                                </Box>
                                <Box className='bannerCategoryThumb'>
                                    <img src={Stores} />
                                    <span>{t('Stores')}</span>
                                </Box>
                            </Box>
                            
                        </Box>
                    </Col>
                </Row>
            </Container>
        </BannerWrapper>
    )
}

export default Banner