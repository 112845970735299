import styled from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Cookies from 'js-cookie';
export const Box = styled.div`
    background-color: ${({ bg }) => bg ? bg : ''};
`
export const Span = styled.span`
    background-color: ${({ bg }) => bg ? bg : ''};
`

export const IconBoxColor = styled.div`
    svg {
        [stroke] {
            stroke: ${({ iconColor }) => iconColor ? iconColor : ''};
        }
    }
`

export const LazyImage = (props) => {
    return (
        <LazyLoadImage className="custom-img"
            {...props}
            effect="blur"
        />
    )
}


export const SpanStyled = styled.span`
    color:${props => props.color ? props.color : ""};
    line-height: ${props => props.lh ? props.lh : ''};
    font-size:${props => props.size ? props.size : ''};
    font-size: ${props => Cookies.get("lang") === "ar" ? "23px" : "13px"};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    /* text-transform: ${props => props.transform ? props.transform : 'uppercase'}; */
    /* font-family: ${props => props.family ? props.family : "var(--regular)"}; */
    font-family: ${props => Cookies.get("lang") === "ar" ? "var(--arLight)" : "var(--regular)"};
`
export const SpanStyled2 = styled.span`
    color:${props => props.color ? props.color : ""};
    line-height: ${props => props.lh ? props.lh : ''};
    font-size:${props => props.size ? props.size : "16px"};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    font-family: ${props => props.family ? props.family : "var(--regular)"};
    /* body.rtl &{
        font-family: "var(--arLight)";
    } */
`

export const HeadingStyled = styled.div`
    color:${props => props.color ? props.color : "var(--themeColor)"};
    line-height: ${props => Cookies.get("lang") === "ar" ? "60px" : "60px"};
    font-size: ${props => Cookies.get("lang") === "ar" ? "80px" : "45px"};
    display: ${props => props.display ? props.display : 'flex'};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    font-family: ${props => Cookies.get("lang") === "ar" ? "var(--arRegular)" : "var(--extraBold)"};
    
`
export const HeadingStyled2 = styled.div`
    color:${props => props.color ? props.color : "var(--themeColor)"};
    line-height: ${props => Cookies.get("lang") === "ar" ? "60px" : "60px"};
    font-size: ${props => props.size ? props.size : '40px'};
    display: ${props => props.display ? props.display : 'flex'};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    font-family: ${props => Cookies.get("lang") === "ar" ? "var(--arRegular)" : "var(--extraBold)"};
    
`
export const HeadingStyledB = styled.div`
    color:${props => props.color ? props.color : "var(--themeColor)"};
    line-height: ${props => Cookies.get("lang") === "ar" ? "40px" : "32px"};
    font-size: ${props => Cookies.get("lang") === "ar" ? "48px" : "25px"};
    display: ${props => props.display ? props.display : 'flex'};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    font-family: ${props => Cookies.get("lang") === "ar" ? "var(--arRegular)" : "var(--extraBold)"};
`

export const HeadingStyledC = styled.div`
    color:${props => props.color ? props.color : "var(--themeColor)"};
    line-height: ${props => Cookies.get("lang") === "ar" ? "40px" : "35px"};
    font-size: ${props => Cookies.get("lang") === "ar" ? "46px" : "24px"};
    display: ${props => props.display ? props.display : 'flex'};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    font-family: ${props => Cookies.get("lang") === "ar" ? "var(--arRegular)" : "var(--extraBold)"};
`

export const HeadingStyledD = styled.div`
    color:${props => props.color ? props.color : "var(--themeColor)"};
    line-height: ${props => Cookies.get("lang") === "ar" ? "50px" : "40px"};
    font-size: ${props => Cookies.get("lang") === "ar" ? "40px" : "24px"};
    display: ${props => props.display ? props.display : 'flex'};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    font-family: ${props => Cookies.get("lang") === "ar" ? "var(--arRegular)" : "var(--bold)"};
`

export const SubHeadingStyled = styled.div`
    color:${props => props.color ? props.color : "var(--themeColor)"};
    line-height: ${props => props.lh ? props.lh : ''};
    font-size:${props => props.size ? props.size : '24px'};
    display: ${props => props.display ? props.display : ''};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : ''};
    font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--regular)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--arRegular)"};
`
export const MainHeading = styled.div`
    color:${props => props.color ? props.color : ""};
    line-height: ${props => props.lh ? props.lh : 'normal'};
    font-size:${props => props.size ? props.size : '60px'};
    display: ${props => props.display ? props.display : ''};
    font-weight:${props => props.weight ? props.weight : ''};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    text-transform: ${props => props.transform ? props.transform : 'uppercase'};
    font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--headingFont)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--arExtraBold)"};
`
export const TextStyled = styled.p`
    display: ${props => props.display ? props.display : 'block'};
    line-height: ${props => Cookies.get("lang") === "ar" ? "30px" : "25px"};
    font-size: ${props => Cookies.get("lang") === "ar" ? "32px" : "16px"};
    color:${props => props.color ? props.color : "var(--black)"};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    font-family: ${props => Cookies.get("lang") === "ar" ? "var(--arLight)" : "var(--medium)"};
`

export const LabelStyled = styled.label`
    line-height: ${props => Cookies.get("lang") === "ar" ? "30px" : "25px"};
    font-size: ${props => Cookies.get("lang") === "ar" ? "32px" : "16px"};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    color:${props => props.color ? props.color : 'var(--black)'};
    font-family: ${props => Cookies.get("lang") === "ar" ? "var(--arRegulr)" : "var(--semiBold)"};
`

export const ButtonStyled = styled.button`
    transition: 0.3s ease-in-out opacity;
    font-size:${props => props.size ? props.size : '16px'};
    font-weight:${props => props.weight ? props.weight : '500'};
    background:${props => props.bg ? props.bg : "none"};
    color: ${props => props.color ? props.color : "var(--ThemeColor)"};
    padding: ${props => props.padding ? props.padding : "11px 20px"};
    border: ${props => props.border ? props.border : "none"};
    width: ${props => props.width ? props.width : "100%"};
    line-height: ${props => props.lineHeight ? props.lineHeight : "normal"};
    border-radius: ${props => props.radius ? props.radius : "8px"};
    letter-spacing: ${props => props.spacing ? props.spacing : ''};
    font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--arMedium)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--medium)"};
    
`