import styled from "styled-components";
import { BannerBg } from "../../Styled/AllImages";

export const HowItWorksWrapper = styled.section`
    padding: 80px 0 60px 0;
    background-color: var(--white);
    background-image: url(${BannerBg});
    background-repeat: repeat;
    position: relative;
    & > *{
        position: relative;
        z-index: 5;
    }
    &:after{
        content: "";
        width: 100%;
        top: 0px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.99) 50%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        z-index: 1;
        height: 100%;
        left: 0px;
        right: 0px;
        z-index: 0;
    }
    .howItWorksMainHeading{
        margin-bottom: 40px;
    }
    .howItWorksContentLayers{
        position: relative;
    }
    


`
export const HowItWorksProcessWrapper = styled.div`
    .howitWorksPoint{
        position: relative;
        padding-left: 83px;
        &:before{
            content: "";
            position: absolute;
            top: -53px;
            left: 23px;
            width: 2px;
            height: 60px;
            background-color: var(--themeColor);
            z-index: -1;
        }
        &:after{
            content: "";
            position: absolute;
            top: 45px;
            left: 23px;
            width: 2px;
            height: 60px;
            background-color: var(--themeColor);
            z-index: -1;
        }
        .howitWorksPointCount{
            position: absolute;
            left: 0;
            top: 5px;
            font-family: var(--semiBold);
            color: var(--white);
            font-size: 22px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            line-height: 42px;
            background-color: var(--themeColor);
            &:before{
                content: "";
                position: absolute;
                top: -6px;
                left: -6px;
                background-color: rgba(2, 117, 90, 0.1);
                width: 60px;
                height: 60px;
                border-radius: 50%;
                z-index: 5;
            }
        }
        &:first-child{
            &:before{
                display: none;
            }
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
    }
`