import styled from "styled-components";

export const AboutWrapper = styled.section`
    padding: 80px 0 60px 0;
    position: relative;
    .aboutImg{
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: -140px;
    }
    .appPlatforms{
        display: flex;
        gap: 7px;
        margin-top: 30px;
    }
    .aboutPattern1{
        right: -112px;
        bottom: 80px;
    }
    .aboutPattern2{
        left: -45px;
        bottom: -150px;
        width: 180px;
    }
    .aboutHglText{
        font-size: 18px;
        font-family: var(--bold);
    }

`
