import styled from 'styled-components'
import Cookies from 'js-cookie';


export const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    padding: 10px 0 10px 0px;
    // transition: all 0.5s cubic-bezier(.77,0,.18,1);
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        display:flex;  
        align-items: center;
        gap: 3px;
    }
    .nav-bar-list a{
        color: var(--black);
        position: relative;
        font-size: ${() => Cookies.get("lang") === "ar" ? "34px" : "17px"};
        font-family: ${() => Cookies.get("lang") === "ar" ? "var(--arRegular)" : "var(--bold)"};
        padding: ${() => Cookies.get("lang") === "ar" ? "6px 19px" : "13px 22px"};
        border-radius: 10px;
        transition: all .4s ease-in-out;
        &:hover, &.active, &:focus{
            background-color : var(--themeColor);
            /* -webkit-text-fill-color: transparent;
            -webkit-background-clip: text; */
            padding: ${() => Cookies.get("lang") === "ar" ? "6px 19px" : "13px 22px"};
            /* border-radius: 10px; */
            color:var(--white);
            &::before, &.active::before{
                content: '';
                position: absolute;
                top: 45px;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                width: 16px;
                height: 16px;
                background: var(--darkGradient);
            }
            &::after, &.active::after{
                content: '';
                position: absolute;
                top: 60px;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                width: 25px;
                height: 3px;
                background: var(--darkGradient);
            }
        }
    }
    .logo{
        cursor:pointer;
        svg{
            width: 270px;
            height: auto;
            transition: all .4s ease-in-out;
        }
    }
    &.sticky{
        background: var(--white);
        box-shadow: 0px 3px 10px rgba(0, 0, 0, .3);
        z-index: 200;
        .logo{
            svg{
                width: 200px;
                height: auto;
            }
        }
    }
    .toggleBtn{
        display: none;
    }



`