import styled from "styled-components";
import { BannerBg } from '../../Styled/AllImages'
import Cookies from 'js-cookie';

export const BannerWrapper = styled.section`
    background-image: url(${BannerBg});
    height: 100vh;
    max-height: 750px;
    min-height: 700px;
    width: 100%;
    background-repeat: repeat;
    position: relative;
    &:after{
        content: "";
        width: 100%;
        bottom: 0px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        z-index: 1;
        height: 200px;
        left: 0px;
        right: 0px;
        z-index: 0;
    }
    .bannerPattern1{
        left: -60px;
        top: 160px;
        width: 130px;
    }
    .bannerPattern2{
        right: 0;
        bottom: 10px;
        width: 150px;
    }
    .container{
        height: 100%;
        position: relative;
        z-index: 1;
        & > .row{
            height: 100%;
            align-items: flex-end;
        }
        .bannerContentWrap{
            display: flex;
            height: 100%;
            align-items: center;
            padding-right: 50px;
            
        }
        .bannerMainHeading{
            margin-bottom: 15px;
            padding-top: 30px;
        }
    }
    .appPlatforms{
        display: flex;
        gap: 7px;
        margin-top: 30px;
    }
    .bannerCategories{
        display: flex;
        width: 100%;
        gap: 25px;
        margin-top: 40px;
        .bannerCategoryThumb{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 2px;
            background-color: var(--themeColor);
            width: 100px;
            height: 90px;
            border-radius: 10px;
            flex-direction: column;
            box-shadow: 0px 12px 12px rgb(0, 0, 0, .2);
            svg{
                width: 300px;
                height: auto;
            }
            span{
                width: 100%;
                text-align: center;
                background : var(--goldGradient);
                font-size: ${() => Cookies.get("lang") === "ar" ? "29px" : "15px"};
                line-height: ${() => Cookies.get("lang") === "ar" ? "26px" : "30px"};
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                font-family: ${() => Cookies.get("lang") === "ar" ? "var(--arRegular)" : "var(--extraBold)"};
            }
        }
    }
`